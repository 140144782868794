import { Notifications } from 'components/common/notifications/Notifications'
import { ENDPOINTS } from 'config/EndPoints'
import { useAxios } from 'hooks/useAxios'
import { INotification } from 'interface/INotification'
import { useCallback, useEffect, useState } from 'react'
import { Table, Button, Tooltip, Input } from 'antd'
import { Link } from 'react-router-dom'
import { ClusterOutlined, LoadingOutlined } from '@ant-design/icons'
import { ORGANIZATIONS_FORM } from 'config/Path'
import { ShowImage } from 'components/common/show-img/ShowImage'
const { Search } = Input
export const OrganizationsList = () => {
    const { get } = useAxios()
    const { errorObjectWithData }: INotification = Notifications()
    const [organizationsList, setOrganizationsList] = useState<any>([])
    const [spinner, setSpinner] = useState(true)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    let height = 0
    const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
    )

    height = vh - 341
    const [param, setParam] = useState({
        page: 1,
        limit: 10,
        search: '',
        column_name: '',
        order: 'descend',
    })

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#13023E' }} spin />
    )

    useEffect(() => {
        loadOrganizationsList()
        return () => {}
    }, [param])

    const loadOrganizationsList = () => {
        setSpinner(true)
        get(ENDPOINTS.GET_ORGANIZATIONS, getParams(), success, error, always)
    }

    const success = useCallback((response: any) => {
        setSpinner(false)

        response.data.data.forEach((element: any) => {
            element.key = element.id
        })
        let totalItems = response.data.data.length
        let total = response.data.total
        setTotalPages(total)
        setTotalItems(totalItems)
        setOrganizationsList(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
        console.log(errors)
    }

    const always = () => {
        setSpinner(false)
    }

    const getParams = () => {
        let result: any = {}
        if (param.page) {
            result.page = param.page
        }
        if (param.column_name) {
            result.column_name = param.column_name
        }
        if (param.limit) {
            result.limit = param.limit
        }
        if (param.order) {
            result.order = param.order
        }
        if (param.search) {
            result.search = param.search
        }
        return result
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        let sortField = sorter.field
        let sortOrder = sorter.order
        let page = pagination

        setParam({
            ...param,
            column_name: sortField,
            order: sortOrder,
            page: page.current,
        })
    }

    const onSearch = (value: any) => {
        setParam({
            ...param,
            search: value,
        })
    }

    const columns: any = [
        {
            title: 'Logo',
            dataIndex: 'file',
            key: 'file',
            fixed: 'left',
            align: 'center',
            width: 100,
            ellipsis: {
                showTitle: false,
            },

            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={item.alias_name}>
                    <ShowImage url={name} alt='logo organizaciones' />
                </Tooltip>
            ),
        },
        {
            title: 'Nombre',
            dataIndex: 'alias_name',
            key: 'alias_name',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    <Link to={`${ORGANIZATIONS_FORM}/${item.id}`}>{name}</Link>
                </Tooltip>
            ),
        },
        {
            title: 'Tipo de Organización',
            dataIndex: 'organization_type',
            key: 'organization_type',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (item: any) => (
                <Tooltip placement='topLeft' title={item.name}>
                    {item.name}
                </Tooltip>
            ),
        },
        {
            title: 'Categoría',
            dataIndex: 'category',
            key: 'category',

            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (aux: any, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={item.subcategory.category.name}>
                    {item.subcategory.category.name}
                </Tooltip>
            ),
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'subcategory',
            width: 200,
            key: 'subcategory',
            // width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (item: any) => (
                <Tooltip placement='topLeft' title={item.name}>
                    {item.name}
                </Tooltip>
            ),
        },
        {
            title: 'Web site',
            dataIndex: 'web_site',
            key: 'web_site',
            // width: 150,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (item: any) => (
                <Tooltip placement='topLeft' title={item}>
                    {item}
                </Tooltip>
            ),
        },
    ]
    return (
        <div id='organization-list'>
            <div className='header'>
                <div className='header-title'>Organizaciones</div>
                <div className='header-button'>
                    <Button type='primary' className='btn-big' shape='round'>
                        <Link to={ORGANIZATIONS_FORM}>
                            Nueva organización <ClusterOutlined />
                        </Link>
                    </Button>
                </div>
            </div>
            <div className='body'>
                <div className='body-title'>
                    Listado de organizaciones
                    <div className='search-box'>
                        <Search
                            placeholder='Buscar'
                            allowClear
                            onSearch={onSearch}
                        />
                    </div>
                </div>
                <div className='body-table'>
                    <Table
                        className='organization-table'
                        sticky
                        bordered
                        pagination={{
                            position: ['bottomRight'],
                            total: totalPages,
                            showTotal: () => `${totalItems} items`,
                        }}
                        scroll={{ x: 800, y: height }}
                        columns={columns}
                        loading={{ spinning: spinner, indicator: antIcon }}
                        dataSource={organizationsList}
                        onChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    )
}
