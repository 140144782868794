export const ENDPOINTS = {
    // ----------------GET----------------

    // Menu
    GET_MENU: 'menus',

    // Organizations
    GET_ORGANIZATION_TYPES: 'organization-types',
    GET_ORGANIZATION: 'organizations/{organization}',
    GET_ORGANIZATIONS: 'organizations',

    // Brands
    GET_BRANDS: 'brands',
    GET_BRAND: 'brands/{brand}',

    // Razon social
    GET_COMPANY_NAMES: 'company-names',
    GET_COMPANY_NAME: 'company-names/{company_name}',

    GET_CATEGORIES: 'categories',
    GET_SUBCATEGORIES: 'categories/{category}/subcategories',

    // ----------------POST----------------

    // Login
    POST_LOGIN: 'login',

    // Organizations
    POST_ORGANIZATION: 'organizations',
    POST_ORGANIZATION_UPDATE: 'organizations/{organization}',

    // Brands
    POST_BRAND: 'brands',
    POST_BRAND_UPDATE: 'brands/{brand}',

    // Razon social
    POST_COMPANY_NAME: 'company-names',

    // ----------------PUT----------------

    // Organizations
    PUT_ORGANIZATION: 'organizations/{organization}',

    // Brands
    PUT_BRAND: 'brands/{brand}',

    // Razon social
    PUT_COMPANY_NAME: 'company-names/{company_name}',
}

export const ENDPOINTS_WITH_OUT_TOKEN = {
    // ----------------GET----------------

    // Organizations
    GET_ORGANIZATIONS_RESOURCE: 'organizations/resource',

    // Razon social
    GET_ORGANIZATIONS_COMPANYS: 'organizations/{organization}/company-names',
    GET_COMPANYS_RESOURCE: 'company-names/resource',

    // Brands
    GET_ORGANIZATIONS_BRNADS: 'organizations/{organization}/brands',
    GET_BRANDS_RESOURCE: 'brands/resource',

    // Countries
    GET_COUNTRIES: 'countries',
    GET_COUNTRY: 'countries/{country}',
    GET_COUNTRY_STATES: 'countries/{country}/states',

    // States
    GET_STATES: 'states',
    GET_STATE: 'states/{state}',

    // Cities
    GET_CITIES: 'cities',
    GET_CITY: 'cities/{city}',
}
