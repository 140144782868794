import { Notifications } from "components/common/notifications/Notifications";
import { useAxios } from "hooks/useAxios";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Form, Button, Modal, Col, Row, Upload, Slider } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "components/common/loader/Loader";
import { ENDPOINTS, ENDPOINTS_WITH_OUT_TOKEN } from "config/EndPoints";

import { CustomSelect } from "components/common/custom-select/CustomSelect";
import { CustomInput } from "components/common/custom-input/CustomInput";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { BRANDS } from "config/Path";

import ImgCrop from "antd-img-crop";
interface RouteParams {
  id: string;
}

interface FormValue {
  name: string | null;
  organization_id: number | null;
  category_id: number | null;
  subcategory_id: number | null;
  web_site: string | null;
  file: string | null;
}

export const BrandsForm = () => {
  const { successObject, errorObjectWithData, errorWithParams } =
    Notifications();
  const [form] = Form.useForm();
  const { get, post, put, getWithOutToken } = useAxios();
  const [formValue, setFormValue] = useState<FormValue | any>({});
  const [spinGeneral, setSpinGeneral] = useState(false);
  const [spinSave, setSpinSave] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [brandId, setBrandId] = useState<any>("");
  const [brandData, setBrandData] = useState<any>(null);

  const [imageSrc, setImageSrc] = useState<any>(undefined);
  const [file, setFile] = useState<any>(undefined);
  const [imgSrc, setImgSrc] = useState<any>(undefined);
  const uploader: any = useRef();

  const location = useLocation<{ [key: string]: unknown }>();
  const history = useHistory();
  const currentLocationState = location.state || {
    from: { pathname: BRANDS },
  };

  let { id } = useParams<RouteParams>();
  if (id && id !== brandId) {
    setBrandId(id);
  }

  const loadbrandData = () => {
    setSpinLoad(true);
    get(
      ENDPOINTS.GET_BRAND.replace("{brand}", brandId),
      {},
      success,
      error,
      always
    );
  };

  const onSave = (values: any) => {
    setSpinSave(true);
    let result = getValues();
    if (brandId) {
      if (file) {
        post(
          ENDPOINTS.POST_BRAND_UPDATE.replace("{brand}", brandId),
          result,
          successSave,
          error,
          alwaysSave
        );
      } else
        put(
          ENDPOINTS.PUT_BRAND.replace("{brand}", brandId),
          result,
          successSave,
          error,
          alwaysSave
        );
    } else {
      post(ENDPOINTS.POST_BRAND, result, successSave, error, alwaysSave);
    }
  };

  useEffect(() => {
    setSpinGeneral(true);
    if (brandId !== "") {
      loadbrandData();
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (brandData) {
      let newFormValue: FormValue | any = {
        name: brandData?.name,
        organization_id: brandData?.organization?.id,
        file: brandData?.file,
      };

      if (brandData?.file) {
        setImgSrc(brandData?.file);
        setFile(brandData?.file);
      }

      form.setFieldsValue(newFormValue);

      setFormValue(newFormValue);
    }
    return () => {};
  }, [brandData]);

  useEffect(() => {
    if (formValue.file !== imageSrc) {
      let newFormValue = { ...formValue };
      newFormValue.file = imageSrc;
      setFormValue(newFormValue);
    }
    return () => {};
  }, [imageSrc]);

  const success = useCallback((response: any) => {
    setSpinLoad(false);
    setBrandData(response.data.data);
  }, []);

  const error = (errors: any) => {
    errorObjectWithData(errors);
  };

  const always = () => {
    setSpinLoad(false);
  };

  const successSave = useCallback((response: any) => {
    setSpinSave(false);
    successObject(response);
    history.push(currentLocationState?.from as string);
  }, []);

  const alwaysSave = () => {
    setSpinSave(false);
  };

  const onChange = (values: any) => {
    let newFormValue: FormValue = { ...formValue, ...values };
    setFormValue(newFormValue);
  };

  const getValues = () => {
    let result: any = {
      name: formValue?.name,
      organization_id: formValue?.organization_id,
    };

    let formData = new FormData();
    formData.append("name", formValue?.name);
    formData.append("organization_id", formValue?.organization_id);

    if (file) {
      formData.append("file", file);
      return formData;
    } else {
      return result;
    }
  };

  const handleChangeUploader = (files: any) => {
    let file = files.target.files[0];
    previewFile(file);
    setFile(file);
  };

  const deleteFile = () => {
    setImageSrc(undefined);
    setFile(undefined);
    setImgSrc(undefined);
  };

  const previewFile = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      setImgSrc(e.target?.result);
    };
    reader.readAsDataURL(file);
  };

  if (!spinLoad && spinGeneral) {
    setSpinGeneral(false);
  }

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const createImage = (url: any) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function beforeUpload(file: any, fileList: any): any {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      errorWithParams(
        "Error",
        "Solo se permiten formatos de archivo JPG/PNG/GIF"
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorWithParams("Error", "La imagen tiene que ser menor o igual a 2MB!");
    }
    if (isJpgOrPng && isLt2M) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img: any = document.createElement("img");
          img.src = reader.result;
          img.onload = async () => {
            const canvas: any = document.createElement("canvas");
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx: any = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            const newBase64 = canvas.toDataURL("image/png");

            const croppedImg = await createImage(newBase64);

            canvas.width = 400;
            canvas.height = 400;
            const contex: any = canvas.getContext("2d");

            contex.drawImage(croppedImg, 0, 0, 400, 400);

            canvas.toBlob(resolve);
          };
        };
      });
      // return true
    } else return false;
  }

  return (
    <>
      <div id="brands-form">
        <Loader className="spin-general" spin={spinGeneral || spinSave}>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={onChange}
            onFinish={onSave}
            size="large"
          >
            <div className="form-container">
              <div className="card general-info">
                <div className="header">Detalle de Marca</div>
                <div className="body">
                  <CustomInput
                    name="name"
                    id="name"
                    label="Nombre de Marca"
                    required={true}
                  />

                  <CustomSelect
                    label="Organización"
                    name="organization_id"
                    id="organization_id"
                    method={get}
                    endpoint={
                      ENDPOINTS_WITH_OUT_TOKEN.GET_ORGANIZATIONS_RESOURCE
                    }
                    value={formValue?.organization_id}
                    required={true}
                  />

                  <Form.Item id="file" name="file" label="Logo">
                    <div id="image-cropper">
                      {file && (
                        <>
                          <div className="uploader-preview-img">
                            <img src={imgSrc} />
                          </div>
                          <div className="delete-button">
                            <DeleteOutlined onClick={deleteFile} />
                          </div>
                        </>
                      )}
                      {!file && (
                        <label
                          className="button-uploader"
                          htmlFor="uploader-input"
                        >
                          Clic para subir archivos
                          <UploadOutlined />
                        </label>
                      )}

                      <input
                        id="uploader-input"
                        ref={uploader}
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={handleChangeUploader}
                        hidden
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
              <div className="card  buttons-footer">
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Button type="primary" size="large" htmlType="submit">
                      Guardar
                    </Button>
                    <Button
                      style={{ margin: "0 16px" }}
                      ghost
                      className="btn-ghost"
                      size="large"
                      onClick={() =>
                        history.push(currentLocationState?.from as string)
                      }
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </Loader>
      </div>
      {/* {imageSrc && (
                <Modal
                    // footer
                    okText='Guardar'
                    cancelText='Cancelar'
                    visible={show}
                    onCancel={close}
                    onOk={saveCroppedImage}>
                    <div id='modal-cropper-container'>
                        {cropper}
                        <div id='cropper-slider'>
                            <Slider
                                min={1}
                                max={100}
                                style={{
                                    margin: 30,
                                    width: 250,
                                }}
                                value={zoom}
                                onChange={setZoom}
                            />
                        </div>
                    </div>
                </Modal>
            )} */}
    </>
  );
};
