import { useCallback, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { ENDPOINTS } from '../../config/EndPoints'
import jwt_decode from 'jwt-decode'
import { ORGANIZATIONS } from '../../config/Path'
import useAuthContext from '../../hooks/useAuthContext'
import { useAxios } from '../../hooks/useAxios'
import { Notifications } from '../common/notifications/Notifications'
import { Form, Row, Col } from 'antd'
import useUserContext from '../../hooks/useUserContext'
import { LoginForm } from './LoginForm'
import imagen from '../../asset/img/imageLogin.jpg'

interface FormValue {
    email: string | null
    password: string | null
}

const Login = () => {
    const { login, isAuthenticated } = useAuthContext()
    const { updateUser } = useUserContext()
    const { postLogin } = useAxios()
    const location = useLocation<{ [key: string]: unknown }>()
    const currentLocationState = location.state || {
        from: { pathname: ORGANIZATIONS },
    }
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [spin, setSpin] = useState(false)
    const [errorBool, setErrorBool] = useState(false)
    const [errorSubmit, setErrorSubmit] = useState({})
    const [form] = Form.useForm()

    const [imageLoaded, setImageLoaded] = useState(false)

    const onChange = (values: any) => {
        let newFormValue: any = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const onSubmit = (values: any) => {
        setSpin(true)
        setErrorBool(false)
        if (values) {
            let data = {
                email: values.email,
                password: values.password,
            }
            postLogin(ENDPOINTS.POST_LOGIN, data, success, error, always)
        }
    }

    const success = useCallback(
        (response: any) => {
            setSpin(false)
            let token = response.data.data.access_token
            let user = jwt_decode(token)
            login(token)
            updateUser(user)
        },
        [setSpin, login],
    )

    const error = (errors: any) => {
        setErrorSubmit(errors)
        setErrorBool(true)
    }

    const always = () => {
        setSpin(false)
    }

    const setForgotBool = (bool: boolean) => {
        setFormValue({})
        form.setFieldsValue({
            email: '',
            password: '',
            forgotEmail: '',
        })
        setErrorBool(false)
    }

    if (isAuthenticated) {
        return <Redirect to={currentLocationState?.from as string} />
    }

    return (
        <div id='login'>
            <Row>
                <Col
                    className='left-container'
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}>
                    <img
                        src={imagen}
                        alt='imagen de login'
                        className={`smooth-image image-${
                            imageLoaded ? 'visible' : 'hidden'
                        }`}
                        onLoad={() => setImageLoaded(true)}
                    />
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className='right-container'>
                        <LoginForm
                            form={form}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            spin={spin}
                            openForgotPassword={setForgotBool}
                            errorBool={errorBool}
                            error={errorSubmit}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login
