import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "../login/Login";
import { PrivateRoute } from "./PrivateRoute";
import MyBreadcrumbs from "./MyBreadcrumbs";
import { PublicRoute } from "./PublicRoute";
import {
  ORGANIZATIONS,
  NOT_FOUND,
  LOGIN,
  LOGOUT,
  PAGE403,
  ORGANIZATIONS_FORM_WITH_PARAMS,
  ORGANIZATIONS_FORM,
  BRANDS,
  BRANDS_FORM_WITH_PARAMS,
  BRANDS_FORM,
  COMPANY_NAMES,
  COMPANY_NAMES_FORM_WITH_PARAMS,
  COMPANY_NAMES_FORM,
} from "../../config/Path";
import { NotFound } from "../notFound/NotFound";
import { Logout } from "../logout/Logout";
import useAuthContext from "../../hooks/useAuthContext";
import { MainMenu } from "../menu/MainMenu";
import { OrganizationsList } from "components/organizations/OrganizationsList";
import { Page403 } from "components/403/Page403";
import { OrganizationsForm } from "components/organizations/OrganizationsForm";
import { BrandsList } from "components/brands/BrandsList";
import { BrandsForm } from "components/brands/BrandsForm";
import { CompanyNamesList } from "components/company-names/CompanyNamesList";
import { CompanyNamesForm } from "components/company-names/CompanyNamesForm";

export const AppRouter = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <>
      <Router>
        <div className="resources-container">
          {isAuthenticated && (
            <>
              <MainMenu />
              <MyBreadcrumbs />
            </>
          )}
          <div className="resources-context">
            <Switch>
              <PrivateRoute exact path="/" component={OrganizationsList} />
              <PrivateRoute
                exact
                path={ORGANIZATIONS}
                component={OrganizationsList}
              />
              <PrivateRoute
                exact
                path={ORGANIZATIONS_FORM_WITH_PARAMS}
                component={OrganizationsForm}
              />
              <PrivateRoute
                exact
                path={ORGANIZATIONS_FORM}
                component={OrganizationsForm}
              />
              <PrivateRoute exact path={BRANDS} component={BrandsList} />
              <PrivateRoute
                exact
                path={BRANDS_FORM_WITH_PARAMS}
                component={BrandsForm}
              />
              <PrivateRoute exact path={BRANDS_FORM} component={BrandsForm} />
              <PrivateRoute
                exact
                path={COMPANY_NAMES}
                component={CompanyNamesList}
              />
              <PrivateRoute
                exact
                path={COMPANY_NAMES_FORM_WITH_PARAMS}
                component={CompanyNamesForm}
              />
              <PrivateRoute
                exact
                path={COMPANY_NAMES_FORM}
                component={CompanyNamesForm}
              />
              <PrivateRoute exact path={PAGE403} component={Page403} />
              <PrivateRoute exact path={LOGOUT} component={Logout} />
              <PublicRoute exact path={LOGIN} component={Login} />
              <PrivateRoute path={NOT_FOUND} component={NotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    </>
  );
};
