import IconsList from '../common/icons-list/IconsList'
import { NavLink } from 'react-router-dom'
import { useAxios } from '../../hooks/useAxios'
import { useCallback, useEffect, useState } from 'react'
import { ENDPOINTS } from '../../config/EndPoints'

import { Tooltip } from 'antd'
import { CONFIG } from '../../config/Config'

interface IProps {
    setToggle: (bool: boolean) => void
    toggle: boolean
}

export const SideBar = ({ ...props }: IProps) => {
    const { setToggle, toggle } = props
    const { get } = useAxios()
    const [menuItems, setMenuItems] = useState<any[]>([])

    useEffect(() => {
        get(ENDPOINTS.GET_MENU, {}, success, error, always)
        return () => {}
    }, [])

    const success = useCallback((response: any) => {
        setMenuItems(response.data.data)

        // Notification.successObject(response)
    }, [])

    const error = (errors: any) => {
        // Notification.errorObjectWithData(errors)
        console.log(errors)
    }

    const always = () => {
        // setSpinner(false)
    }

    const RenderMenu = () => {
        let data = menuItems.map((item: any, index: any) => {
            let content = IconsList(item.icon)
            let text = <span className='text-menu'>{item.name}</span>
            const tooltip = <span className='tooltip-menu'>{item.name}</span>

            return (
                <NavLink
                    key={index}
                    exact
                    to={item.url}
                    activeClassName='menu-item-active'
                    onClick={() => setToggle(!toggle)}>
                    <Tooltip
                        id='menu-tooltip'
                        placement='right'
                        color='#13023E'
                        key='#13023E'
                        trigger='hover'
                        title={tooltip}>
                        <span className={`cursor menu-item`}>
                            <div className='selected'></div>
                            <div className='item'>
                                {content}
                                {text}
                            </div>
                        </span>
                    </Tooltip>
                </NavLink>
            )
        })
        return data
    }

    return (
        <>
            <div
                className={`backdrop ${toggle ? 'active' : ''}`}
                onClick={() => setToggle(!toggle)}></div>
            <div id='sidebar' className={toggle ? 'active' : ''}>
                <div className='menu'>{menuItems && RenderMenu()}</div>
                <div className='logo-tdt'>
                    <div className='logo'></div>
                </div>
            </div>
        </>
    )
}
