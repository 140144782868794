export const NOT_FOUND: string = '*'
export const LOGOUT: string = '/logout'
export const LOGIN: string = '/login'
export const PAGE403: string = '/unauthorized'
export const ORGANIZATIONS: string = '/organizations'
export const NEW_ORGANIZATIONS: string = '/new-organizations'
export const ORGANIZATIONS_FORM_WITH_PARAMS: string =
    '/organizations/new-organizations/:id'
export const ORGANIZATIONS_FORM: string = '/organizations/new-organizations'
export const BRANDS: string = '/brands'
export const NEW_BRANDS: string = '/new-brands'
export const BRANDS_FORM_WITH_PARAMS: string = '/brands/new-brands/:id'
export const BRANDS_FORM: string = '/brands/new-brands'
export const COMPANY_NAMES: string = '/company-names'
export const NEW_COMPANY_NAMES: string = '/new-company-names'
export const COMPANY_NAMES_FORM_WITH_PARAMS: string =
    '/company-names/new-company-names/:id'
export const COMPANY_NAMES_FORM: string = '/company-names/new-company-names'

export const PROPOSAL: string = '/proposals'
export const PURCHASE_ORDERS: string = '/purchase-orders'
export const COPY: string = '/copy'
export const CREATIVES: string = '/creatives'
export const IMPLEMENTATION: string = '/implementation'
export const CHECKING: string = '/checking'
export const ACCOUNTS: string = '/accounts'
export const USERS: string = '/users'
export const BRIEFS_FORM_ALONG: string = '/briefs-form'
