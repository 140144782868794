import { useState } from 'react'
import { NavBar } from './NavBar'
import { SideBar } from './SideBar'

export const MainMenu = () => {
    const [toggle, setToggle] = useState(false)
    return (
        <div id='mainmenu'>
            <NavBar toggle={toggle} setToggle={setToggle} />
            <SideBar toggle={toggle} setToggle={setToggle} />
        </div>
    )
}
