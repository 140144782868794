import { Dropdown, Menu } from 'antd'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
} from '@ant-design/icons'
import logo from '../../asset/img/TDT-Logo-Color.svg'
import useUserContext from '../../hooks/useUserContext'
import { Link } from 'react-router-dom'
import { LOGOUT } from '../../config/Path'

interface IProps {
    setToggle: (bool: boolean) => void
    toggle: boolean
}

export const NavBar = ({ ...props }: IProps) => {
    const { user } = useUserContext()
    const { setToggle, toggle } = props

    const menuUser = (
        <Menu>
            <Menu.Item key='1'>
                <Link to={LOGOUT}>Cerrar sesión</Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <div id='navbar'>
            <div className='left'>
                <div className='button-toggle'>
                    {toggle ? (
                        <MenuUnfoldOutlined
                            className='icon-navbar'
                            onClick={() => setToggle(!toggle)}
                        />
                    ) : (
                        <MenuFoldOutlined
                            className='icon-navbar'
                            onClick={() => setToggle(!toggle)}
                        />
                    )}
                </div>
                <div className='logo'>
                    <img alt='logo tdt resources' src={logo} />
                </div>
            </div>
            <div className='right'>
                <Dropdown overlay={menuUser} trigger={['click']}>
                    <div className='rigth-item user ant-dropdown-link'>
                        <div className='icon'>
                            <UserOutlined className='icon-navbar' />
                        </div>
                        <div className='username'>{user?.given_name}</div>
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}
