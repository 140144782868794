import { Notifications } from 'components/common/notifications/Notifications'
import { useAxios } from 'hooks/useAxios'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { Form, Button, Modal, Col, Row, Upload, Slider } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Loader } from 'components/common/loader/Loader'
import { ENDPOINTS } from 'config/EndPoints'
import { CustomSelect } from 'components/common/custom-select/CustomSelect'
import { CustomInput } from 'components/common/custom-input/CustomInput'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { ORGANIZATIONS } from 'config/Path'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from 'components/common/canvasUtils'
import { CustomUploader } from 'components/common/custom-uploader/CustomUploader'

interface RouteParams {
    id: string
}

interface FormValue {
    alias_name: string | null
    organization_type_id: number | null
    category_id: number | null
    subcategory_id: number | null
    web_site: string | null
    file: string | null
}

export const OrganizationsForm = () => {
    const { successObject, errorObjectWithData, errorWithParams } =
        Notifications()
    const [form] = Form.useForm()
    const { get, post, put, getHeaders } = useAxios()
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [spinGeneral, setSpinGeneral] = useState(false)
    const [spinSave, setSpinSave] = useState(false)
    const [spinLoad, setSpinLoad] = useState(false)
    const [organizationId, setorganizationId] = useState<any>('')
    const [organizationData, setOrganizationData] = useState<any>(null)

    // -----------------------------------------------------------
    const [imageSrc, setImageSrc] = useState<any>(undefined)
    const [file, setFile] = useState<any>(undefined)
    const [imgSrc, setImgSrc] = useState<any>(undefined)
    const uploader: any = useRef()

    // -----------------------------------------------------------

    const location = useLocation<{ [key: string]: unknown }>()
    const history = useHistory()
    const currentLocationState = location.state || {
        from: { pathname: ORGANIZATIONS },
    }

    let { id } = useParams<RouteParams>()
    if (id && id !== organizationId) {
        setorganizationId(id)
    }

    const loadOrganizationData = () => {
        setSpinLoad(true)
        get(
            ENDPOINTS.GET_ORGANIZATION.replace(
                '{organization}',
                organizationId,
            ),
            {},
            success,
            error,
            always,
        )
    }

    const onSave = (values: any) => {
        setSpinSave(true)
        let result = getValues()
        if (organizationId) {
            if (file) {
                post(
                    ENDPOINTS.POST_ORGANIZATION_UPDATE.replace(
                        '{organization}',
                        organizationId,
                    ),
                    result,
                    successSave,
                    error,
                    alwaysSave,
                )
            } else {
                put(
                    ENDPOINTS.PUT_ORGANIZATION.replace(
                        '{organization}',
                        organizationId,
                    ),
                    result,
                    successSave,
                    error,
                    alwaysSave,
                )
            }
        } else {
            post(
                ENDPOINTS.POST_ORGANIZATION,
                result,
                successSave,
                error,
                alwaysSave,
            )
        }
    }

    useEffect(() => {
        setSpinGeneral(true)
        if (organizationId !== '') {
            loadOrganizationData()
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (organizationData) {
            let newFormValue: FormValue | any = {
                alias_name: organizationData?.alias_name,
                organization_type_id: organizationData?.organization_type?.id,
                category_id: organizationData?.subcategory?.category?.id,
                subcategory_id: organizationData?.subcategory?.id,
                web_site: organizationData?.web_site,
                file: organizationData?.file,
            }

            if (organizationData?.file) {
                setImgSrc(organizationData?.file)
                setFile(organizationData?.file)
            }

            form.setFieldsValue(newFormValue)

            setFormValue(newFormValue)
        }
        return () => {}
    }, [organizationData])

    useEffect(() => {
        if (formValue.file !== imageSrc) {
            let newFormValue = { ...formValue }
            newFormValue.file = imageSrc
            setFormValue(newFormValue)
        }
        return () => {}
    }, [imageSrc])

    const success = useCallback((response: any) => {
        setSpinLoad(false)
        setOrganizationData(response.data.data)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setSpinLoad(false)
    }

    const successSave = useCallback((response: any) => {
        setSpinSave(false)
        successObject(response)
        history.push(currentLocationState?.from as string)
    }, [])

    const alwaysSave = () => {
        setSpinSave(false)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        if (values.category_id) {
            newFormValue.subcategory_id = null
        }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            alias_name: formValue?.alias_name,
            organization_type_id: formValue?.organization_type_id,
            subcategory_id: formValue?.subcategory_id,
            web_site: formValue?.web_site,
        }

        let formData = new FormData()
        formData.append('alias_name', result.alias_name)
        formData.append('organization_type_id', result.organization_type_id)
        formData.append('subcategory_id', result?.subcategory_id)
        formData.append('web_site', result?.web_site)

        if (file) {
            formData.append('file', file)
            return formData
        } else {
            return result
        }
    }

    const onChangeFile = (imageSrc: any, file: any) => {
        setImageSrc(imageSrc)
        setFile(file)
    }

    if (!spinLoad && spinGeneral) {
        setSpinGeneral(false)
    }

    const handleChangeUploader = (files: any) => {
        let file = files.target.files[0]
        previewFile(file)
        setFile(file)
    }

    const deleteFile = () => {
        setImageSrc(undefined)
        setFile(undefined)
        setImgSrc(undefined)
    }

    const previewFile = (file: any) => {
        const reader = new FileReader()
        reader.onloadend = (e) => {
            setImgSrc(e.target?.result)
        }
        reader.readAsDataURL(file)
    }

    return (
        <>
            <div id='organizations-form'>
                <Loader className='spin-general' spin={spinGeneral || spinSave}>
                    <Form
                        form={form}
                        layout='vertical'
                        onValuesChange={onChange}
                        onFinish={onSave}
                        scrollToFirstError
                        size='large'>
                        <div className='form-container'>
                            <div className='card general-info'>
                                <div className='header'>
                                    Detalle de la organización
                                </div>
                                <div className='body'>
                                    <CustomInput
                                        name='alias_name'
                                        id='alias_name'
                                        label='Nombre'
                                        required={true}
                                    />

                                    <CustomSelect
                                        label='Tipo de organización'
                                        name='organization_type_id'
                                        id='organization_type_id'
                                        endpoint={
                                            ENDPOINTS.GET_ORGANIZATION_TYPES
                                        }
                                        value={formValue?.organization_type_id}
                                        required={true}
                                    />
                                    <CustomSelect
                                        label='Categoría'
                                        name='category_id'
                                        id='category_id'
                                        endpoint={ENDPOINTS.GET_CATEGORIES}
                                        value={formValue?.category_id}
                                        required={true}
                                    />
                                    <CustomSelect
                                        label='Sub Categoría'
                                        name='subcategory_id'
                                        id='subcategory_id'
                                        endpoint={
                                            formValue.category_id &&
                                            ENDPOINTS.GET_SUBCATEGORIES.replace(
                                                '{category}',
                                                formValue.category_id,
                                            )
                                        }
                                        value={formValue?.subcategory_id}
                                        required={true}
                                        disabled={!formValue.category_id}
                                    />
                                    <CustomInput
                                        name='web_site'
                                        id='web_site'
                                        type='url'
                                        label='Sitio Web'
                                        required={true}
                                    />

                                    {/* <CustomUploader
                                        name='file'
                                        id='file'
                                        label='Logo'
                                        value={formValue.file}
                                        onChange={onChangeFile}
                                    /> */}

                                    <Form.Item
                                        id='file'
                                        name='file'
                                        label='Logo'>
                                        <div id='image-cropper'>
                                            {file && (
                                                <>
                                                    <div className='uploader-preview-img'>
                                                        <img src={imgSrc} />
                                                    </div>
                                                    <div className='delete-button'>
                                                        <DeleteOutlined
                                                            onClick={deleteFile}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {!file && (
                                                <label
                                                    className='button-uploader'
                                                    htmlFor='uploader-input'>
                                                    Clic para subir archivos
                                                    <UploadOutlined />
                                                </label>
                                            )}

                                            <input
                                                id='uploader-input'
                                                ref={uploader}
                                                type='file'
                                                accept='.png, .jpg, .jpeg, .gif'
                                                onChange={handleChangeUploader}
                                                hidden
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='card  buttons-footer'>
                                <Row>
                                    <Col
                                        span={24}
                                        style={{ textAlign: 'center' }}>
                                        <Button
                                            type='primary'
                                            size='large'
                                            htmlType='submit'>
                                            Guardar
                                        </Button>
                                        <Button
                                            style={{ margin: '0 16px' }}
                                            ghost
                                            className='btn-ghost'
                                            size='large'
                                            onClick={() =>
                                                history.push(
                                                    currentLocationState?.from as string,
                                                )
                                            }>
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Loader>
            </div>
        </>
    )
}
