import { useEffect } from 'react'
import useAuthContext from '../../hooks/useAuthContext'
import useUserContext from '../../hooks/useUserContext'

export const Logout = () => {
    const { logout } = useAuthContext()
    const { updateUser } = useUserContext()

    useEffect(() => {
        logout()
        updateUser(null)
        return () => {}
    }, [])

    return null
}
