import { Notifications } from 'components/common/notifications/Notifications'
import { useAxios } from 'hooks/useAxios'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { Form, Button, Col, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Loader } from 'components/common/loader/Loader'
import { ENDPOINTS, ENDPOINTS_WITH_OUT_TOKEN } from 'config/EndPoints'
import { CustomSelect } from 'components/common/custom-select/CustomSelect'
import { CustomInput } from 'components/common/custom-input/CustomInput'
import { BRANDS, COMPANY_NAMES, ORGANIZATIONS } from 'config/Path'
import { Functions } from 'components/common/Functions'

interface RouteParams {
    id: string
}

interface FormValue {
    name: string | null
    fiscal_number: number | null
    organization_id: number | null
    country_id: number | null

    file: string | null
}

export const CompanyNamesForm = () => {
    const { successObject, errorObjectWithData, errorWithParams } =
        Notifications()
    const [form] = Form.useForm()
    const { get, post, put, getWithOutToken } = useAxios()
    const [formValue, setFormValue] = useState<FormValue | any>({})
    const [spinGeneral, setSpinGeneral] = useState(false)
    const [spinSave, setSpinSave] = useState(false)
    const [spinLoad, setSpinLoad] = useState(false)
    const [spinCountries, setSpinCountries] = useState(false)
    const [companyNamesId, setCompanyNamesId] = useState<any>('')
    const [companyNamesData, setCompanyNamesData] = useState<any>(null)
    const [country, setCountry] = useState<any>(null)

    const location = useLocation<{ [key: string]: unknown }>()
    const history = useHistory()
    const currentLocationState = location.state || {
        from: { pathname: COMPANY_NAMES },
    }

    let { id } = useParams<RouteParams>()
    if (id && id !== companyNamesId) {
        setCompanyNamesId(id)
    }

    const loadcompanyNamesData = () => {
        setSpinLoad(true)
        get(
            ENDPOINTS.GET_COMPANY_NAME.replace(
                '{company_name}',
                companyNamesId,
            ),
            {},
            success,
            error,
            always,
        )
    }

    const onSave = (values: any) => {
        setSpinSave(true)
        let result = getValues()
        if (companyNamesId) {
            put(
                ENDPOINTS.PUT_COMPANY_NAME.replace(
                    '{company_name}',
                    companyNamesId,
                ),
                result,
                successSave,
                error,
                alwaysSave,
            )
        } else {
            post(
                ENDPOINTS.POST_COMPANY_NAME,
                result,
                successSave,
                error,
                alwaysSave,
            )
        }
    }

    useEffect(() => {
        setSpinGeneral(true)
        if (companyNamesId !== '') {
            loadcompanyNamesData()
        }
        if (!country) {
            loadCountries()
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (companyNamesData) {
            let newFormValue: FormValue | any = {
                name: companyNamesData?.name,
                organization_id: companyNamesData?.organization?.id,
                fiscal_number: companyNamesData?.fiscal_number,
                country_id: companyNamesData?.country.id,
            }

            form.setFieldsValue(newFormValue)

            setFormValue(newFormValue)
        }
        return () => {}
    }, [companyNamesData])

    const loadCountries = () => {
        setSpinCountries(true)
        getWithOutToken(
            ENDPOINTS_WITH_OUT_TOKEN.GET_COUNTRIES,
            {},
            successCountry,
            error,
            alwaysCountries,
        )
    }

    const success = useCallback((response: any) => {
        setSpinLoad(false)
        setCompanyNamesData(response.data.data)
    }, [])

    const successCountry = useCallback((response: any) => {
        setSpinCountries(false)
        let dataProcessed = response.data.data.reduce(
            (acc: any[], item: any) => [
                ...acc,
                {
                    label: item?.attributes?.name,
                    currency: item.attributes?.currency,
                    geolocation: item.attributes?.geolocation,
                    iso_31661: item.attributes?.iso_31661,
                    locale: item.attributes?.locale,
                    value: item.id,
                    en: item.translations?.en?.name,
                    es: item.translations?.es?.name,
                    pt: item.translations?.pt?.name,
                },
            ],
            [],
        )
        let countriesList: any[] =
            Functions.getCountriesByLanguage(dataProcessed)
        setCountry(countriesList)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
    }

    const always = () => {
        setSpinLoad(false)
    }

    const alwaysCountries = () => {
        setSpinCountries(false)
    }

    const successSave = useCallback((response: any) => {
        setSpinSave(false)
        successObject(response)
        history.push(currentLocationState?.from as string)
    }, [])

    const alwaysSave = () => {
        setSpinSave(false)
    }

    const onChange = (values: any) => {
        let newFormValue: FormValue = { ...formValue, ...values }
        setFormValue(newFormValue)
    }

    const getValues = () => {
        let result: any = {
            name: formValue?.name,
            organization_id: formValue?.organization_id,
            fiscal_number: formValue?.fiscal_number,
            country_id: formValue?.country_id,
        }
        if (formValue?.file) {
            result.file = formValue?.file
        }

        return result
    }

    if (!spinLoad && !spinCountries && spinGeneral) {
        setSpinGeneral(false)
    }

    return (
        <>
            <div id='company-names-form'>
                <Loader className='spin-general' spin={spinGeneral || spinSave}>
                    <Form
                        form={form}
                        layout='vertical'
                        onValuesChange={onChange}
                        onFinish={onSave}
                        size='large'>
                        <div className='form-container'>
                            <div className='card general-info'>
                                <div className='header'>
                                    Detalle de razón social
                                </div>
                                <div className='body'>
                                    <CustomInput
                                        name='fiscal_number'
                                        id='fiscal_number'
                                        label='Número de Razón Social'
                                        type='number'
                                        required={true}
                                    />

                                    <CustomInput
                                        name='name'
                                        id='name'
                                        label='Nombre'
                                        required={true}
                                    />

                                    <CustomSelect
                                        label='Organización'
                                        name='organization_id'
                                        id='organization_id'
                                        method={get}
                                        endpoint={
                                            ENDPOINTS_WITH_OUT_TOKEN.GET_ORGANIZATIONS_RESOURCE
                                        }
                                        value={formValue?.organization_id}
                                        required={true}
                                    />

                                    <CustomSelect
                                        label='País'
                                        name='country_id'
                                        id='country_id'
                                        data={country}
                                        value={formValue?.country_id}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className='card  buttons-footer'>
                                <Row>
                                    <Col
                                        span={24}
                                        style={{ textAlign: 'center' }}>
                                        <Button
                                            type='primary'
                                            size='large'
                                            htmlType='submit'>
                                            Guardar
                                        </Button>
                                        <Button
                                            style={{ margin: '0 16px' }}
                                            ghost
                                            className='btn-ghost'
                                            size='large'
                                            onClick={() =>
                                                history.push(
                                                    currentLocationState?.from as string,
                                                )
                                            }>
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Loader>
            </div>
        </>
    )
}
