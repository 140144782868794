import React from 'react'
import ReactDOM from 'react-dom'
import { AppRouter } from './components/router/AppRouter'
import AuthContextProvider from './context/AuthContext'
import UserContextProvider from './context/UserContext'

import 'antd/dist/antd.css'
import './asset/scss/index.scss'

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <UserContextProvider>
                <AppRouter />
            </UserContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
