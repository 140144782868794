import { AuditOutlined, ClusterOutlined, StarOutlined } from '@ant-design/icons'

const IconsList = (name: string) => {
    const list = [
        { name: 'AuditOutlined', icon: <AuditOutlined /> },
        { name: 'ClusterOutlined', icon: <ClusterOutlined /> },
        { name: 'StarOutlined', icon: <StarOutlined /> },
    ]
    let result = list.find((item: any) => item.name === name)

    return result?.icon
}

export default IconsList
