import { IUser } from '../interface/IUser'
import { IUserContext } from '../interface/IUserContext'
import { createContext } from 'react'
import { useCallback, useState, useMemo } from 'react'
import { LocalStorage } from '../common/LocalStorage'

export const UserContext = createContext<any>({})

const UserContextProvider = ({ children }: any) => {
    let userLogin: any = LocalStorage.getUser()
    let parsed = JSON.parse(userLogin)

    const [user, setUser] = useState<any | null>(parsed)

    const updateUser = useCallback((user: IUser) => {
        setUser(user)
    }, [])

    const value: IUserContext = useMemo(
        () => ({
            updateUser,
            user,
        }),
        [user, updateUser],
    )

    // @ts-ignore
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
