import { Button, notification } from 'antd'
import { INotification } from 'interface/INotification'

export const Notifications = () => {
    const success = (title: string, description: string) => {
        notification.success({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const info = (title: string, description: string) => {
        notification.info({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const warning = (title: string, description: string) => {
        notification.warning({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const errorWithParams = (title: string, description: string) => {
        notification.error({
            message: title,
            description: description,
            duration: 5,
            placement: 'bottomLeft',
            style: {
                left: '30px',
            },
        })
    }

    const successObject = (response: any) => {
        let description = 'Los datos se han guardado con éxito'

        if (response.data.message) {
            description = response.data.message
        } else if (response.message) {
            description = response.message
        }

        success('Guardado', description)
    }

    const notificationObject = (response: any) => {
        let description = 'Los datos se han guardado con éxito'

        if (response.data.message) {
            description = response.data.message
        } else if (response.message) {
            description = response.message
        }

        success('Mensaje', description)
    }

    const errorObject = (errors: any) => {
        let description = 'Ha ocurrido un error al guardar'

        if (
            errors.response &&
            errors.response.data &&
            errors.response.data.message
        ) {
            description = errors.response.data.message
        } else if (errors.response && errors.response.message) {
            description = errors.response.message
        } else if (errors.message) {
            description = errors.message
        }

        errorWithParams('Error', description)
    }

    const errorObjectWithData = (errors: any) => {
        let description = 'Ha ocurrido un error al guardar'

        if (
            errors.response &&
            errors.response.data &&
            errors.response.data.message
        ) {
            description = errors.response.data.message
        } else if (errors.response && errors.response.message) {
            description = errors.response.message
        } else if (errors.message) {
            description = errors.message
        }

        if (errors.response.data.errors) {
            let keys = Object.keys(errors.response.data.errors)
            let value: any
            keys.forEach((element) => {
                value = errors.response.data.errors[element]
                if (Array.isArray(value)) {
                    if (value.length === 1) {
                        description = value[0]
                    }
                }
            })
        }

        errorWithParams('Error', description)
    }

    const deleteObject = (response: any) => {
        let description = 'Los datos se han eliminado con éxito'

        if (response.data.message) {
            description = response.data.message
        } else if (response.message) {
            description = response.message
        }

        success('Eliminado', description)
    }

    return {
        success,
        info,
        warning,
        errorWithParams,
        successObject,
        deleteObject,
        notificationObject,
        errorObject,
        errorObjectWithData,
    }
}
