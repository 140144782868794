import axios from 'axios'
import { CONFIG } from '../config/Config'
import { Redirect } from 'react-router-dom'
import { PAGE403, LOGIN } from '../config/Path'
import useAuthContext from './useAuthContext'
import { LocalStorage } from '../common/LocalStorage'

export const useAxios = () => {
    const { isAuthenticated, logout } = useAuthContext()
    const token = LocalStorage.getToken()
    let authorization: any = isAuthenticated ? `Bearer ${token}` : undefined

    const redirectLogin = () => {
        logout()
        return (
            <Redirect
                to={{
                    pathname: LOGIN,
                }}
            />
        )
    }

    const redirect403 = () => {
        return (
            <Redirect
                to={{
                    pathname: PAGE403,
                }}
            />
        )
    }

    const get = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios
            .get(CONFIG.END_POINT + pathRelative, {
                params: data,
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorization,
                    'Access-Control-Allow-Origin': '*',
                },
                validateStatus: function (status: any) {
                    return validateStatus(status)
                },
            })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
    }

    const getWithOutToken = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios
            .get(CONFIG.END_POINT + pathRelative, {
                params: data,
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                validateStatus: function (status: any) {
                    return validateStatus(status)
                },
            })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
    }

    const post = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'post',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorization,
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
    }

    const postLogin = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'post',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                } else {
                    let errors = {
                        response: {
                            data: {
                                message: 'Error de servidor',
                            },
                        },
                    }
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
    }

    const put = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios({
            method: 'put',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            baseURL: CONFIG.END_POINT,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorization,
                'Access-Control-Allow-Origin': '*',
            },
            validateStatus: function (status: any) {
                return validateStatus(status)
            },
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
    }

    const validateStatus = (status: number) => {
        let success = 200
        let successCreated = 201
        let unauthorized = 401
        let unauthenticated = 403
        if (status === unauthorized) {
            redirectLogin()
        }
        if (status === unauthenticated) {
            redirect403()
        }

        return status === success || status === successCreated
    }

    const getHeaders = () => {
        return {
            authorization,
            'Access-Control-Allow-Origin': '*',
        }
    }

    return { get, post, put, postLogin, getHeaders, getWithOutToken }
}
