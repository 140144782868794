import { Breadcrumb } from 'antd'
import { ORGANIZATIONS } from '../../config/Path'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { pathTraslate } from 'config/PathTraslate'

interface PublicRouteParams extends RouteComponentProps {}

const MyBreadcrumbs = ({ ...props }: PublicRouteParams) => {
    const {
        location: { pathname },
    } = props
    let pathnames: any[] = pathname.split('/').filter((x) => x)

    let lastName: any = pathnames[pathnames.length - 1]
    if (!isNaN(lastName)) {
        pathnames.pop()
        lastName = pathnames[pathnames.length - 1]
    }
    lastName = pathTraslate(lastName)
    return (
        <>
            {pathnames.length > 1 ? (
                <div className='breadcrumb'>
                    <Breadcrumb>
                        {pathnames.length > 1 &&
                            pathnames.map((name, index) => {
                                const routeTo = `/${pathnames
                                    .slice(0, index + 1)
                                    .join('/')}`
                                const nameToShow = pathTraslate(name)
                                const isLast = index === pathnames.length - 1
                                return isLast ? (
                                    <Breadcrumb.Item key={nameToShow}>
                                        {nameToShow}
                                    </Breadcrumb.Item>
                                ) : (
                                    <Breadcrumb.Item key={nameToShow}>
                                        <Link to={routeTo}>{nameToShow}</Link>
                                    </Breadcrumb.Item>
                                )
                            })}
                    </Breadcrumb>
                    <div className='breadcrumb-title'>{lastName}</div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export default withRouter(MyBreadcrumbs)
