import { Form, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'

interface IProps {
    id?: string | any
    label: string
    name: string
    className?: string | any
    required?: boolean
    requiredMessage?: string
    placeholder?: string
    showTooltips?: boolean
    value?: any
    disabled?: boolean
    onChange?: any
    tooltipTitle?: string
    type?: string | any
}

export const CustomInput = ({ ...props }: IProps) => {
    const {
        id,
        label,
        name,
        className,
        required = false,
        disabled = false,
        requiredMessage = 'Obligatorio',
        placeholder = 'Seleccione una opción',
        value,
        tooltipTitle,
        showTooltips,
        onChange,
        type,
    } = props

    const [selectedValue, setSelectedValue] = useState<any>(null)

    useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
        return () => {}
    }, [value])

    const configTooltips = showTooltips
        ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
          }
        : false

    return (
        <Form.Item
            id={id}
            name={name}
            className={className}
            label={label}
            rules={[
                {
                    required: required,
                    message: requiredMessage,
                },
            ]}
            tooltip={configTooltips}>
            {type === 'password' ? (
                <Input.Password
                    type={type}
                    disabled={disabled}
                    placeholder={'Seleccione'}
                />
            ) : (
                <Input
                    type={type}
                    disabled={disabled}
                    placeholder={'Seleccione'}
                />
            )}
        </Form.Item>
    )
}
