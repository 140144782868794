import { createContext, useCallback, useMemo, useState } from 'react'
import { LocalStorage } from '../common/LocalStorage'
import { IAuthContext } from '../interface/IAuthContext'
import jwt_decode from 'jwt-decode'

export const AuthContext = createContext<any>({})

const AuthContextProvider = ({ children }: any) => {
    const [isAuthenticated, setIsAuthenticated] = useState<any>(
        LocalStorage.getToken(),
    )

    const login = useCallback(
        (token: any) => {
            LocalStorage.setToken(token)
            let user = jwt_decode(token)
            LocalStorage.setUser(JSON.stringify(user))
            setIsAuthenticated(true)
        },
        [setIsAuthenticated],
    )

    const logout = useCallback(() => {
        LocalStorage.removeToken()
        LocalStorage.removeUser()
        setIsAuthenticated(false)
    }, [setIsAuthenticated])

    const value: IAuthContext = useMemo(
        () => ({
            login,
            logout,
            isAuthenticated,
        }),
        [isAuthenticated, login],
    )

    // @ts-ignore
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
