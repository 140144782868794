import { Loader } from 'components/common/loader/Loader'
import { Notifications } from 'components/common/notifications/Notifications'
import { ENDPOINTS } from 'config/EndPoints'
import { useAxios } from 'hooks/useAxios'
import { INotification } from 'interface/INotification'
import { useCallback, useEffect, useState } from 'react'
import { Table, Button, Tooltip, Input } from 'antd'
import { Link } from 'react-router-dom'
import { AuditOutlined, LoadingOutlined } from '@ant-design/icons'
import { BRANDS_FORM, COMPANY_NAMES_FORM } from 'config/Path'
import { ShowImage } from 'components/common/show-img/ShowImage'
const { Search } = Input
export const CompanyNamesList = () => {
    const { get } = useAxios()
    const { errorObjectWithData }: INotification = Notifications()
    const [companyNamesList, setCompanyNamesList] = useState<any>([])
    const [spinner, setSpinner] = useState(true)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItems, setTotalItems] = useState(0)

    let height = 0
    const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
    )

    height = vh - 341
    const [param, setParam] = useState({
        page: 1,
        limit: 10,
        search: '',
        column_name: '',
        order: 'descend',
    })

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#13023E' }} spin />
    )

    useEffect(() => {
        loadCompanyNamesList()
        return () => {}
    }, [param])

    const loadCompanyNamesList = () => {
        setSpinner(true)

        get(ENDPOINTS.GET_COMPANY_NAMES, getParams(), success, error, always)
    }

    const success = useCallback((response: any) => {
        setSpinner(false)
        response.data.data.forEach((element: any) => {
            element.key = element.id
        })
        let totalItems = response.data.data.length
        let total = response.data.total
        setTotalPages(total)
        setTotalItems(totalItems)
        setCompanyNamesList(response.data.data)
        // Notification.successObject(response)
    }, [])

    const error = (errors: any) => {
        errorObjectWithData(errors)
        console.log(errors)
    }

    const always = () => {
        setSpinner(false)
    }

    const getParams = () => {
        let result: any = {}
        if (param.page) {
            result.page = param.page
        }
        if (param.column_name) {
            result.column_name = param.column_name
        }
        if (param.limit) {
            result.limit = param.limit
        }
        if (param.order) {
            result.order = param.order
        }
        if (param.search) {
            result.search = param.search
        }
        return result
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        let sortField = sorter.field
        let sortOrder = sorter.order
        let page = pagination

        setParam({
            ...param,
            column_name: sortField,
            order: sortOrder,
            page: page.current,
        })
    }

    const onSearch = (value: any) => {
        setParam({
            ...param,
            search: value,
        })
    }

    const columns: any = [
        {
            title: 'Número de Razón Social',
            dataIndex: 'fiscal_number',
            key: 'fiscal_number',
            fixed: 'left',
            // align: 'center',
            // width: 100,
            ellipsis: {
                showTitle: false,
            },

            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    {name}
                </Tooltip>
            ),
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            // fixed: 'left',
            // width: 50,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (name: any, item: any) => (
                <Tooltip placement='topLeft' title={name}>
                    <Link to={`${COMPANY_NAMES_FORM}/${item.id}`}>{name}</Link>
                </Tooltip>
            ),
        },
        {
            title: 'Organización',
            dataIndex: 'organization',
            key: 'organization',
            // width: 50,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (item: any) => (
                <Tooltip placement='topLeft' title={item.name}>
                    {item.name}
                </Tooltip>
            ),
        },
        {
            title: 'País',
            dataIndex: 'country',
            key: 'country',
            // width: 50,
            ellipsis: {
                showTitle: false,
            },
            sorter: true,
            render: (item: any) => (
                <Tooltip placement='topLeft' title={item.name}>
                    {item.name}
                </Tooltip>
            ),
        },
    ]
    return (
        <div id='company-names-list'>
            <div className='header'>
                <div className='header-title'>Razones sociales</div>
                <div className='header-button'>
                    <Button type='primary' className='btn-big' shape='round'>
                        <Link to={COMPANY_NAMES_FORM}>
                            Nueva razón social <AuditOutlined />
                        </Link>
                    </Button>
                </div>
            </div>
            <div className='body'>
                <div className='body-title'>
                    Listado de razones sociales
                    <div className='search-box'>
                        <Search
                            placeholder='Buscar'
                            allowClear
                            onSearch={onSearch}
                        />
                    </div>
                </div>
                <div className='body-table'>
                    <Table
                        className='company-names-table'
                        sticky
                        bordered
                        pagination={{
                            position: ['bottomRight'],
                            total: totalPages,
                            showTotal: () => `${totalItems} items`,
                        }}
                        scroll={{ x: 0, y: height }}
                        columns={columns}
                        loading={{ spinning: spinner, indicator: antIcon }}
                        dataSource={companyNamesList}
                        onChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    )
}
