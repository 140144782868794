import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
interface IProps {
    className?: string
    spin: boolean
}
export const Loader = ({ children, ...props }: any) => {
    const { className = '', spin } = props
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#13023E' }} spin />
    )

    return (
        <Spin className={className} indicator={antIcon} spinning={spin}>
            {children}
        </Spin>
    )
}
