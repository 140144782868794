export const pathTraslate = (key: string): string => {
    let data: any = {
        'new-organizations': 'Nueva organización',
        organizations: 'Organización',
        brands: 'Marcas',
        'new-brands': 'Nueva marca',
        'company-names': 'Razón social',
        'new-company-names': 'Nueva razón social',
    }
    if (data[key]) {
        return data[key]
    }

    console.log('traducción no encontrada para: ', key)
    return ''
}
